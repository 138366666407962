import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Layout } from '@/layouts'
import SEO from '@/components/seo'

const utamemoBanar = require('@/images/utamemo_thumbnail.jpg')
const calgalyThumbnail = require('@/images/calgaly_thumbnail.png')

const Products: React.FC<{}> = (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title='プロダクト一覧ページ'
        description='taikishiinoのプロダクト一覧ページです。'
      />
      <StyledComponent>
        <Link to='/products/calgaly_β' className='product-card'>
          <div className='product-card__image'>
            <img src={calgalyThumbnail} alt='calgaly_β banar' />
          </div>
          <div className='product-card__body'>
            <h2><span>＞ </span>CalGaly申込・予約システム β版</h2>
            <div className='discription'>
              スキー・スノーボード年間保管＆配送サービスCalGalyの申し込み・予約を行えるLINE
            </div>
          </div>
        </Link>
        <Link to='/products/utamemo' className='product-card'>
          <div className='product-card__image'>
            <img src={utamemoBanar} alt='utamemo banar' />
          </div>
          <div className='product-card__body'>
            <h2><span>＞ </span>カラオケメモアプリ - うためも</h2>
            <div className='discription'>
              シンプル機能で歌いながら簡単メモできるカラオケメモアプリ
            </div>
          </div>
        </Link>
      </StyledComponent>
    </Layout>
  )
}
export default Products

const StyledComponent = styled.div`
  .product-card {
    width: 100%;
    display: flex;
    text-decoration: none;
    border-bottom: 2px solid #e6e6e6;
    padding: 20px 0;

    .product-card__image {
      padding-left: 24px;

      img {
        width: 240px;
        height: 120px;
        object-fit: cover;
        border-radius: 12px;
        border: 2px solid #f0f0ee;
      }
    }

    .product-card__body {
      padding-left: 32px;
      font-family: 'Noto Sans Japanese', '游ゴシック体', 'Yu Gothic', YuGothic,
        'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,
        Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

      h2 {
        display: inline-block;
        font-size: 20px;
        color: #000;
        margin: 8px 0 12px;

        span {
          font-weight: bold;
          color: #2cb696;
        }
      }
      .discription {
        color: #787c7b;
        white-space: pre-wrap;
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
    .product-card {
      display: block;

      .product-card__image {
        height: 160px;
        padding: 0 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .product-card__body {
        padding: 0 22px;
        font-family: 'Noto Sans Japanese', '游ゴシック体', 'Yu Gothic', YuGothic,
          'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ',
          Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

        span {
          font-weight: 900;
          color: #2cb696;
        }

        h2 {
          display: inline-block;
          line-height: 1.5;
          color: #000;
          margin-top: 8px;
          font-size: 16px;
        }
        .discription {
          color: #787c7b;
          white-space: pre-wrap;
          font-size: 12px;
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
  }
`
